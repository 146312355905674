.delivery-header.ant-layout-header {
  display: flex;
  padding-right: 0;
  line-height: 0;
  background: #e6f7ff !important;
  box-shadow: 0 2px 8px cornflowerblue;
}

.delivery-header-special.ant-layout-header {
  display: flex;
  padding-right: 0;
  line-height: 0;
  background: #8cc53e !important;
  box-shadow: 0 2px 8px #8cc53e;
}

.delivery-osaka-header.ant-layout-header {
  display: flex;
  padding-right: 0;
  line-height: 0;
  background: #ffffff !important;
  box-shadow: 0 2px 8px #00000020;
}

.delivery-header-logo-text {
  margin-left: 12px;
  font-weight: 100;
  font-size: 20px;
  color: #096dd9;
  cursor: pointer;
}

.delivery-osaka-header-logo-text {
  margin-left: 12px;
  font-weight: 100;
  font-size: 20px;
  color: #000000 !important;
  cursor: pointer;
}

.delivery-header-text {
  color: #096dd9;
}

.delivery-left-header {
  /* width: 50%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.delivery-right-header {
  /* width: 50%; */
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1em;
}

.ant-btn.delivery-header-button {
  padding: 0 10px;
  margin-right: 10px;
}

.ant-dropdown-trigger.delivery-header-menu-dropdown {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.ant-dropdown-trigger.delivery-header-menu-responsive-dropdown {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 1em;
}

.delivery-header-account-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.anticon.delivery-header-menu-icon {
  margin-left: 5px;
  margin-right: 0;
}

.delivery-header-menu-nav-link {
  color: #ffffff;
}

.delivery-header-menu-nav {
  margin-left: 80px;
}
