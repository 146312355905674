body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logo {
  height: 64px;
  font-size: 18px;
  line-height: 64px;
  float: left;
  color: #096dd9;
}

.header {
  background: #e6f7ff !important;
  box-shadow: 0 2px 8px cornflowerblue;
  margin-bottom: 10px;
}

.delivery-spinner {
  margin: 25% 50%;
}

/* #root {
  width: 100%;
  height: 100%;
  display: grid;
} */

.login-box-shadow {
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12), 0 2px 5px rgba(0, 0, 0, 0.24);
  border-width: 0;
}

.ant-upload-list-item {
  width: 400px;
}
