.deliver-info-osaka .ant-table-thead > tr > th {
  background: #0045a3;
  color: #ffffff;
}

.csv-button-osaka,
.csv-button-osaka:hover {
  background: #0045a3;
  color: #ffffff;
}

.required::after {
  content: "必須";
  background-color: #f04e4e;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 3px 7px;
  margin: 0px 5px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
}

.ant-progress-text {
  color: #000000;
}
